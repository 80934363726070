@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;600&family=Roboto:ital,wght@0,400;0,700;1,400;1,700&display=swap');

:root {
  --primaryColor: #faf9f8;
  --primaryWhite: #ffffff;
  --secColor: #E45417;
  --secColor2: #ee8051;
  --secColor3: #421902;
  --terColor: #FCF1EB;
  --terColor2: #ad9486;
  --appBg: #faf9f8;
}

body {
  font-family: 'Roboto', sans-serif !important;
  color: var(--primaryPurple) !important;
}

.fullPage {
  min-height: 80vh;
  min-width: 100vw;
}

.App {
  background-color: var(--appBg);
  font-family: 'Roboto', sans-serif !important;
  color: var(--primaryPurple) !important;
  min-height: 100vh;
}

a {
  color: var(--appBg) !important;
}

.a2 {
  color: var(--secColor) !important;
}

.newsreel-container {
  overflow: hidden;
  white-space: nowrap;
  padding-top: 30px;
  padding-bottom: 10px;
  width: 100vw; /* Ensure the container takes up the full width of its parent */
}

.newsreel-content {
  display: inline-flex; /* Make items display in a row */
  padding: 0 5px; /* Apply spacing between items */
}

.newsreel-item {
  /* Your card styling here */
  margin-right: 5px; /* Optional margin between items */
}